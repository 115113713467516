import { graphql, useStaticQuery } from 'gatsby'

export interface Tags {
  allMdx: {
    group: {
      fieldValue: string
      totalCount: number
    }[]
  }
}

export const useTags = () => {
  const data: Tags = useStaticQuery(graphql`
    query {
      allMdx(filter: { frontmatter: { draft: { eq: false } } }) {
        group(field: frontmatter___tags) {
          fieldValue
          totalCount
        }
      }
    }
  `)
  return data.allMdx.group
}
