import React from 'react'
import styled from 'styled-components'

import IndexLayout from '~/layouts'
import { SEO, Page, Container, Tag, Typography } from '~/components'
import { SEOProps } from '~/components/SEO'
import { useTags } from '~/hooks/useTags'

const StyledListItem = styled.li`
  margin-bottom: ${(p) => p.theme.spacings[1]}rem;
`

const TagsPage = () => {
  const seo: SEOProps = {
    title: 'Tags',
    path: '/tags',
    article: false,
  }
  const tags = useTags()
  return (
    <IndexLayout>
      <SEO
        title={seo.title}
        description={seo.description}
        image={seo.image}
        path={seo.path}
        article={seo.article}
      />
      <Page>
        <Container>
          <Typography variant="h1">Tag Archive</Typography>
          <ul>
            {tags.map((tag) => (
              <StyledListItem key={tag.fieldValue}>
                <Tag to={tag.fieldValue}>
                  {tag.fieldValue} [{tag.totalCount}]
                </Tag>
              </StyledListItem>
            ))}
          </ul>
        </Container>
      </Page>
    </IndexLayout>
  )
}

export default TagsPage
